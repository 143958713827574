import { Box, Button, Card, CardContent } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../deepClone";
import { _valueGet } from "../utils";
import { rStateReducer } from "./utils";
import WizzFunction from "./WizzFunction";
import WizzOperator from "./WizzOperator";
import WizzProperty from "./WizzProperty";

type WizzardProps = {
  onSetRule: Function;
  onCancel: Function;
  input: any;
  options: { _optionsIndex: any; _options: any };
  outterStateSetter: Function;
};

export const Wizzard = forwardRef(
  (
    { input, options, outterStateSetter, onSetRule, onCancel }: WizzardProps,
    ref
  ) => {
    const initReducerState = _valueGet(
      input,
      input["field"]["property"],
      input["field"]["function"],
      input["field"]["operator"],
      deepClone(options._optionsIndex),
      deepClone(options._options)
    );
    const [rState, dispatch] = useImmerReducer(rStateReducer, initReducerState);
    const properties = useMemo(
      () =>
        preparePropertiesForWizzProperty(
          deepClone(options._options["properties"])
        ),
      [options._options]
    );
    // const [properties, setProperties] = useState(
    //   preparePropertiesForWizzProperty(options._options["properties"])
    // );
    const [functions, setFunctions] = useState(
      prepareFunctionsForWizzFunction(
        options._options["functions"],
        options._optionsIndex,
        rState["field"]["property"],
        rState["field"]["function"]
      )
    );
    const [operators, setOperators] = useState(
      prepareOperatorsForWizzOperator(
        options._options["operators"],
        options._optionsIndex,
        rState["field"]["property"],
        rState["field"]["function"],
        rState["field"]["operator"]
      )
    );
    useEffect(() => {
      const _function = prepareFunctionsForWizzFunction(
        options._options["functions"],
        options._optionsIndex,
        rState["field"]["property"],
        rState["field"]["function"]
      );
      setFunctions(_function);

      const _operator = prepareOperatorsForWizzOperator(
        options._options["operators"],
        options._optionsIndex,
        rState["field"]["property"],
        rState["field"]["function"],
        rState["field"]["operator"]
      );
      setOperators(_operator);
    }, [options._options, options._optionsIndex, rState]);

    useEffect(() => {
      //! if rState is not reseted when input changes
      //! wizzard keeps the old state inside, since
      //! it's not unmounting from the dom.
      const _payload = _valueGet(
        input,
        input["field"]["property"],
        input["field"]["function"],
        input["field"]["operator"],
        deepClone(options._optionsIndex),
        deepClone(options._options)
      );
      dispatch({
        type: "changeOperativeInput",
        payload: _payload,
      });
    }, [dispatch, input, options._options, options._optionsIndex]);

    //!!
    //!!
    //!!-------------------------------------------------------
    //!!-------------------------------------------------------
    //!!  level of synchronization with the upper component
    useImperativeHandle(ref, () => ({
      getState: () => {
        const rStateCloned = deepClone(rState);
        return { field: rStateCloned["field"], index: rStateCloned["index"] };
      },
    }));
    //!!-------------------------------------------------------
    //!!-------------------------------------------------------
    //!!
    //!!

    const setRuleButtonHandler = useMemo(() => {
      if (rState["errorAtInputValue"] === true) {
        return true;
      }

      const arrVals = Object.values(rState["field"]);
      for (let i = 0; i < arrVals.length; i++) {
        if (arrVals[i] == null) {
          return true;
        }
      }
      return false;
    }, [rState]);

    useEffect(() => {
      // passing rState values to upper
      // component to set <Help/> State
      if (!setRuleButtonHandler) {
        outterStateSetter({
          showHelp: true,
          property: rState.field.property,
          operator: rState.field.operator,
          operatorParam:
            rState.field?.operatorParams != null
              ? rState.field?.operatorParams?.subject
              : null,
        });
      } else {
        outterStateSetter({
          showHelp: false,
        });
      }
    }, [outterStateSetter, rState, setRuleButtonHandler]);

    return (
      <>
        <Box
          sx={{
            bgcolor: "transparent",
            display: "flex",
            gap: 2,
            height: "100%",
          }}
        >
          {rState && (
            <>
              <WizzProperty
                customStyle={{ maxWidth: "50%", flex: "4 1" }}
                properties={properties}
                rState={rState}
                propertyId={rState["field"]["property"]}
                options={deepClone(options)}
                dispatcher={dispatch}
                isEditing={rState["editing"]}
                sortedList={[
                  "Fundamentals",
                  "Trendrating Analytics",
                  "Performance",
                  "Volatility",
                  "Size",
                ]}
              />
            </>
          )}

          {rState["showFunctionSection"] && (
            <div style={{ maxWidth: "30%", flex: "3 1" }}>
              <WizzFunction
                functionId={rState["field"]["function"]}
                functions={functions}
                rState={rState}
                options={deepClone(options)}
                dispatcher={dispatch}
              />
            </div>
          )}
          {rState["showOperatorSection"] && (
            <div
              style={{
                maxWidth: "30%",
                flex: "3 1",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <WizzOperator
                customStyle={
                  rState["showValueSection"] ? { height: "100%" } : {}
                }
                operatorId={rState.field?.operatorParams?.subject ?? null}
                operators={operators}
                rState={rState}
                options={deepClone(options)}
                dispatcher={dispatch}
                key={rState["field"]["function"]}
              />
            </div>
          )}
        </Box>

        <Box sx={{ pt: 0 }}>
          <Card
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              boxShadow: 3,
            }}
          >
            <CardContent sx={{ "&:last-child": { pb: 2 } }}>
              <Button
                type="button"
                disabled={setRuleButtonHandler}
                onClick={() => onSetRule()}
              >
                Set rule
              </Button>
              <Button
                sx={{
                  ml: 2,
                }}
                variant="tr_button_cancel"
                type="button"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
            </CardContent>
          </Card>
        </Box>
      </>
    );
  }
);

const preparePropertiesForWizzProperty = (options: []) => {
  let arrProperties: { label: string; value: string; builderGroup: string }[] =
    [];
  options.forEach((property) => {
    arrProperties.push({
      label: property["label"],
      value: property["value"],
      builderGroup: property["builderGroup"],
    });
  });
  return arrProperties;
};
const prepareFunctionsForWizzFunction = (
  functionOptions: [],
  optionsIndex,
  propertyIdFromActualRule,
  functionIdFromActualRule
) => {
  if (propertyIdFromActualRule != null) {
    // const function_INDEX =
    //   optionsIndex[propertyIdFromActualRule]["functions"][functionIdFromActualRule];
    const property_INDEX = optionsIndex[propertyIdFromActualRule]["property"];
    return functionOptions[property_INDEX];
  }
  return null;
};
const prepareOperatorsForWizzOperator = (
  operatorOptions: [],
  optionsIndex,
  propertyIdFromActualRule,
  functionIdFromActualRule,
  operatorIdFromActualRule
) => {
  if (propertyIdFromActualRule != null && functionIdFromActualRule != null) {
    const property_INDEX = optionsIndex[propertyIdFromActualRule]["property"];
    const function_INDEX =
      optionsIndex[propertyIdFromActualRule]["functions"][
        functionIdFromActualRule
      ];
    return operatorOptions[property_INDEX][function_INDEX];
  }
  return null;
};
