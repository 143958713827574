/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module api/compute/Taxon
 * @summary Requests and manipulation methods for taxonomies
 *
 */

/**
 * Format the taxon prefixing the parent if taxon.type === taxonType
 *
 * @param {object} taxon - the taxon to be formatted
 * @param {array} taxonomies - taxonomies on which retrieve parent
 * @param {string} taxonType - the type of the taxon (parent)
 */
export function formatTaxonPrefixingParent(
  taxon: any,
  taxonomies: any,
  taxonType: any
) {
  let formatted = taxon["name"];

  if (taxon["type"] === taxonType) {
    const parent = getTaxonById(taxon["parent"], taxonomies);
    formatted = parent["name"] + " - " + formatted;
  }

  return formatted;
}

/**
 * Format the taxon prefixing ancestor
 *
 * @param {object} taxon - the taxon to be formatted
 * @param {array} taxonomies - taxonomies on which retrieve parent
 * @param @param {string} ancestorType - the type of the ancestor
 */
export function formatTaxonPrefixingAncestor(
  taxon: any,
  taxonomies: any,
  ancestorType: any
) {
  let formatted = taxon["name"];

  if (ancestorType != null) {
    let i = 0;
    let parent = null;

    while (parent == null && i < taxonomies.length) {
      parent = getAncestorByType(taxon["id"], taxonomies[i], ancestorType);
      i++;
    }

    if (parent != null && parent["id"] !== taxon["id"]) {
      formatted = parent["name"] + " - " + formatted;
    }
  }

  return formatted;
}

export function getTaxonomyByField(field, type, fieldsMap, rawTaxonomies) {
  const taxonKey = fieldsMap[type]?.[field] ?? fieldsMap["security"]?.[field];
  return rawTaxonomies?.[taxonKey] ?? null;
}

/**
 * Retrives the first matching taxon among taxonomies (order matters)
 *
 * @param {string} taxonId - the taxonony to which the taxon belongs. Default null
 * @param {array} taxonomies - taxonomies on which perform search
 * @param {number} taxonomyIndex - the index of taxonomy (in taxonomies)
 *   to be used
 */
export function getTaxonById(
  taxonId: any,
  taxonomies: any,
  taxonomyIndex?: any
): any {
  taxonomyIndex =
    taxonomyIndex === undefined || taxonomyIndex == null ? 0 : taxonomyIndex;

  const taxon = taxonomies[taxonomyIndex][taxonId];

  if (taxon !== undefined) {
    return taxon;
  } else if (taxonomyIndex === taxonomies.length - 1) {
    return taxonId;
  } else {
    return getTaxonById(taxonId, taxonomies, taxonomyIndex + 1);
  }
}

/**
 * Retrives a taxon ancestor with the specified type (level)
 *
 * @param {string} taxonId - the ID of the taxon
 * @param {object} taxonomy - the taxonomy to which the taxon belongs.
 *      Default null
 * @param {string} ancestorType - the type of the ancestor
 */
export function getAncestorByType(
  taxonId: any,
  taxonomy: any,
  ancestorType: any
): any {
  if (taxonomy[taxonId] === undefined) {
    return null;
  }

  if (
    taxonomy[taxonId].parent == null ||
    taxonomy[taxonId].type === ancestorType
  ) {
    return taxonomy[taxonId];
  }

  return getAncestorByType(taxonomy[taxonId].parent, taxonomy, ancestorType);
}

/**
 *
 * @param {string}node
 * @param {string} level
 *
 * @returns an array with the nodes that are children of a given node. The children are filtered by a specified level.
 * For example if you want all nodes who has Communications Service as parent but you specify that you want only the nodes that are
 * of type "3 Sector", you need to traverse the hierarchy tree and cut off the nodes that has level 2 supersector.
 */
export function getChildrenAtLevel(node: string, level: string, hierarchy) {
  // Lookup for the node in map
  //
  // If the node type is equal to the level just return it because it has no children
  if (hierarchy?.[node] != null && hierarchy[node]?.type === level) {
    return [hierarchy[node]];
  }

  const hierarchyAsList = Object.values<any>(hierarchy);

  const children: any = [];

  const traverseTree = (node) => {
    return hierarchyAsList
      .filter((element) => element.parent === node)
      .map((child) => {
        if (child.type === level) {
          children.push(child);
        }

        return traverseTree(child.id);
      });
  };

  traverseTree(node);

  return children;
}

/**
 *
 * @param {any} a
 * @param {any} b
 * @param {object} taxonomy
 * @param {level} string
 *
 * Sort an array of taxonomy fields at certain level
 */
export const sortAtLevel = (a, b, taxonomy, level) => {
  let aNode = taxonomy[a];
  let bNode = taxonomy[b];

  if (aNode.type === level && bNode.type === level) {
    if (aNode.name.toLowerCase() > bNode.name.toLowerCase()) {
      return 1;
    } else if (aNode.name.toLowerCase() < bNode.name.toLowerCase()) {
      return -1;
    }

    return 0;
  } else {
    if (aNode.type !== level) {
      a = aNode.parent;

      if (a == null) {
        return aNode.name > bNode.name ? 1 : -1;
      }
    }

    if (bNode.type !== level) {
      b = bNode.parent;

      if (b == null) {
        return aNode.name > bNode.name ? 1 : -1;
      }
    }

    return sortAtLevel(a, b, taxonomy, level);
  }
};
