import { ProductClass } from "../../../../types/Defaults";

type HelpContentDownloadProps = {
  productClass: ProductClass;
  staticAssets: string;
};

export const HelpContentDownload = ({
  productClass,
  staticAssets,
}: HelpContentDownloadProps) => {
  const assetsUrl = `${staticAssets}/software`;

  return (
    <div
      className="tPageHelpIndex-contentPage tHelpPage"
      data-id="desktop-applications"
    >
      <div className="help">
        <div className="help-layout">
          <div className="help-left">
            <div>
              <h1>Desktop applications</h1>
              <p>
                If you are installing in a corporate setting, check with your
                system administrator first.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="help-layout">
          <div className="help-left">
            <div className="help-box help-box--50 help-box--alt">
              <h2>Trendrating Smart Manager</h2>
              <div className="help-box-content">
                <p>
                  Install Trendrating Smart Manger to load baskets and
                  portfolios automatically into your Trendrating account.
                  <br />
                  Furthermore, receive daily Alerts on rating changes directly
                  on your desktop.
                </p>

                <p>
                  System requirement: MS Windows 7 or higher (32 bit and 64 bit)
                </p>
                <br />
                <p>Download:</p>
                <p>
                  <a href={`${assetsUrl}/SmartManagerSetup.zip`}>
                    Smart Manager v2.2.0
                  </a>
                </p>
                <p>
                  <a
                    href={`${staticAssets}/user-guides/Trendrating Smart Manager 2.2.0 - User Guide.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    User Guide - v2.2.0
                  </a>
                </p>
              </div>
            </div>
            <div className="help-box help-box--50 help-box--alt">
              <h2>Trendrating MS Excel&reg; Add-In</h2>
              <div className="help-box-content">
                <p>
                  Install Trendrating MS Excel &reg; Add-In to integrate
                  Trendrating's data into your spreadsheets.
                </p>
                <p>
                  System requirement: MS Windows 7 or higher (32bit or 64 bit)
                </p>
                <br />
                <p>Download:</p>
                <p>
                  Version 32 bit:&nbsp;
                  <a href={`${assetsUrl}/TrendratingAddInInstaller32_new.zip`}>
                    Excel Add-In v2.3.0
                  </a>
                </p>
                <p>
                  Version 64 bit:&nbsp;
                  <a href={`${assetsUrl}/TrendratingAddInInstaller64_new.zip`}>
                    Excel Add-In v2.3.0
                  </a>
                </p>
                <p>
                  <a
                    href={`${staticAssets}/user-guides/TrendratingExcelAdd-In-UserGuide.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    User Guide
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
