import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../hooks/useFormatter";
import Search from "../../../../../widgets/ReactSearch/Search";
import Table from "./Table";
import { getTotalWeights } from "./utils";
import DeleteIcon from "@mui/icons-material/Delete";
import { CellComponent, ColumnDefinition } from "tabulator-tables";
import { createRoot } from "react-dom/client";

type Props = {
  type: "PORTFOLIO" | "BASKET";
  positions: any[];
  updateTime: string | null;
  addHoldingHandler: Function;
  isLoading: boolean;
  updateHoldingHandler: Function;
  removeHoldingHandler: Function;
  listenerEqualize: Function;
  listenerNormalize: Function;
};

export default function TableSection({
  type,
  positions,
  updateTime,
  addHoldingHandler,
  updateHoldingHandler,
  isLoading,
  removeHoldingHandler,
  listenerEqualize,
  listenerNormalize,
}: Props) {
  const environment = useEnvironment();
  const instrumentsAPI = useMemo(() => {
    return new Instruments(environment.get("setup"));
  }, [environment]);

  //#region - FORMATERS
  const formatter = useFormatter();
  const priceFormatter = useCallback(
    (cell: CellComponent) => {
      const data = cell.getData();
      return (
        formatter.custom("price", {
          options: {
            isPercentage: false,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: data["vc"],
          valueHelper: {
            currency: data["currency"],
          },
        }) ?? ""
      );
    },
    [formatter]
  );
  const weightFormatter = useCallback(
    (cell: CellComponent) => {
      const data = cell.getData();
      return (
        formatter.custom("number", {
          options: {
            isPercentage: true,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: data["weight"],
        }) ?? ""
      );
    },
    [formatter]
  );
  const ratingFormatter = useCallback(
    (cell: CellComponent) => {
      const data = cell.getData();
      const rate = data["rc"];
      if (rate) {
        return (
          formatter.custom("rating", {
            options: {
              notAvailable: {
                input: 0,
                output: "-",
              },
            },
            output: "HTML",
            value: rate,
            valueHelper: {
              rateChange: data["lr"],
              rateDate: data["dr"],
              ratePrev: data["rrr"],
            },
          }) ?? ""
        );
      }
      return "-";
    },
    [formatter]
  );
  //#endregion

  //#region - COLUMNS AND ROWS
  const columns = useMemo<ColumnDefinition[]>(() => {
    let cols: ColumnDefinition[] = [];
    if (type === "PORTFOLIO") {
      cols.push({
        title: "Weights",
        field: "weight",
        widthGrow: 2,
        formatter: weightFormatter,
        cellDblClick: (e, cell) => {
          let input = document.createElement("input");
          input.type = "number";
          input.step = "0.01";
          let value = (cell.getValue() * 100).toFixed(2);
          input.value = value;
          input.style.width = "100%";
          input.style.height = "100%";
          input.style.border = "black 1px solid";
          input.style.borderRadius = "4px";
          const cc = cell.getElement();
          cc.replaceChildren(input);
          input.focus();

          input.addEventListener("keydown", (e: any) => {
            if (e.key === "Enter") {
              cell.setValue(`${e.target.value / 100}`);
              input.remove();
              weightFormatter(cell);
            }
          });

          input.addEventListener("blur", (e: any) => {
            cell.setValue(`${e.target.value / 100}`);
            input.remove();
            weightFormatter(cell);
            let _value = cell.getRow().getData();
            updateHoldingHandler({
              weight: _value.weight,
              symbol: _value.symbol,
            });
          });

          return cc;
        },
      });
    }
    const _cols = [
      {
        title: "Ticker",
        field: "ticker",
        widthGrow: 1,
      },
      {
        title: "Name",
        field: "name",
        widthGrow: 4,
      },
      {
        title: "Price",
        field: "vc",
        formatter: priceFormatter,
        widthGrow: 2,
      },
      {
        title: "Rating",
        field: "rc",
        formatter: ratingFormatter,
        widthGrow: 1,
      },
      {
        title: "Market",
        field: "_s_where",
        widthGrow: 2,
      },
      {
        title: "",
        field: "",
        formatter: (cell: CellComponent) => {
          let cc = cell.getElement();
          const btn = document.createElement("div");
          btn.style.display = "flex";
          btn.style.justifyContent = "center";
          cc.style.padding = "0";

          const root = createRoot(cc);

          root.render(
            <IconButton
              size="small"
              onClick={() => {
                let data: any = cell.getData();
                removeHoldingHandler(data.symbol);
              }}
            >
              <DeleteIcon sx={{ fontSize: "0.9vw" }} />
            </IconButton>
          );
          cc.appendChild(btn);
          return btn;
        },
      },
    ];
    cols = [...cols, ..._cols];
    return cols;
  }, [
    priceFormatter,
    ratingFormatter,
    removeHoldingHandler,
    type,
    updateHoldingHandler,
    weightFormatter,
  ]);
  const [rowData, setRowData] = useState([]);
  //#endregion

  useEffect(() => {
    if (positions.length > 0) {
      const symbols = positions.map((item) => item.symbol);
      const properties: any = {
        properties: [
          {
            date: null,
            property: "type",
          },
          {
            date: null,
            property: "vc",
          },
          {
            date: null,
            property: "rc",
          },
          {
            date: null,
            property: "name",
          },
          {
            date: null,
            property: "ticker",
          },
          {
            date: null,
            property: "country",
          },
        ],
        symbols: symbols,
        type: "security",
      };
      instrumentsAPI.fetch(properties).then((response) => {
        let _response = response.data;

        for (let i = 0; i < _response.length; i++) {
          for (let j = 0; j < positions.length; j++) {
            if (positions[j].symbol === _response[i].symbol) {
              _response[i]["weight"] = positions[j].weight;
            }
          }
        }

        var market: any = null;
        var position: any = null;
        const txField = environment.get("setup")["taxonomyFields"];
        const taxonomies = environment.get("setup")["taxonomies"];
        var taxonomyWhere = taxonomies[txField["security"]["country"]];
        for (let i = 0, length = _response.length; i < length; i++) {
          position = _response[i];
          if (position.type === "ETF") {
            taxonomyWhere = taxonomies[txField["ETF"]["etfgeo"]];
          }

          market = taxonomyWhere[position["country"]];
          position["_s_where"] = market["name"];

          if (position["weight"] == null) {
            position["weight"] = 0;
          }
        }

        setRowData(_response);
      });
    }
    setRowData([]);
  }, [environment, instrumentsAPI, positions]);

  return (
    <Card
      sx={{ boxShadow: 3, height: "100%", maxHeight: "100%", width: "50%" }}
    >
      <CardContent
        sx={{
          overflow: "hidden",
          height: "100%",
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search
            disabled={isLoading}
            cleanOnSelect={true}
            hasRecents={true}
            showInstrumentInfoOnSelect={false}
            placeHolder="Add new holding"
            onSelectInstrument={addHoldingHandler}
          />
          {type === "PORTFOLIO" && (
            <Box display={"flex"} gap={2} flex={1} justifyContent={"flex-end"}>
              <Button
                disabled={isLoading}
                size="small"
                onClick={() => listenerNormalize()}
              >
                <Typography>100%</Typography>
              </Button>
              <Button
                onClick={() => listenerEqualize()}
                disabled={isLoading}
                size="small"
              >
                <Typography>Equal weighted</Typography>
              </Button>
            </Box>
          )}
        </Box>
        {type === "PORTFOLIO" && !isLoading && (
          <Box>
            <Typography>
              Double click on column <strong>"weights"</strong> to edit the
              weight percentage
            </Typography>
          </Box>
        )}
        {isLoading && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography>Loading data...</Typography>
            <CircularProgress />
          </Box>
        )}
        {!isLoading && <Table columns={columns} rows={rowData} />}
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} gap={2}>
            {type === "PORTFOLIO" && (
              <Chip
                label={
                  <Typography>
                    Total weight:{" "}
                    <strong>
                      {positions ? getTotalWeights(positions).toFixed(2) : 0}%
                    </strong>
                  </Typography>
                }
              />
            )}
            <Chip
              label={
                <Typography>
                  Number of positions: <strong>{positions?.length}</strong>
                </Typography>
              }
            />
          </Box>
          {updateTime && (
            <Box>
              <Chip
                label={
                  <Typography>
                    <span
                      dangerouslySetInnerHTML={{ __html: updateTime }}
                    ></span>
                  </Typography>
                }
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
