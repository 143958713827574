/**
 * @author Trendrating <info@trendrating.net>
 *
 * @module trendrating-report/data/input/CollectSystematic
 * @summary Retrieves data to build report
 *
 */

import { Instruments } from "../../../../api/compute/Instruments";
import { Lists } from "../../../../api/compute/Lists";
import { Strategies } from "../../../../api/compute/Strategies";
import { SystematicProducts } from "../../../../api/compute/SystematicProducts";
import { Utils } from "../../../../api/compute/Utils";
import { httpAll } from "../../../../httpAll";
import { ListPosition, SystematicProduct } from "../../../../types/Api";
import { AppEnvironment } from "../../../../types/Defaults";
import { Product } from "../../../app/storage/SystematicPortfoliosStorage";
import { sectionProperties } from "../../configuration/sectionProperties";
import { WysiwygState } from "../Types";
import {
  getDataSinceFinalDate,
  getDataSinceLaunchDate,
  prepareAllocationAtMap,
  prepareSnapshotAtMap,
  prepareSystematicPortfoliosCurves,
} from "../Utils";

export class CollectSystematic {
  apiInstruments: Instruments;
  apiLists: Lists;
  apiStrategies: Strategies;
  apiSystematicProducts: SystematicProducts;
  apiUtils: Utils;
  apiProduct: Product;

  //! custom fields
  strategyFinalDate: any; // End / final date computed from strategy run
  strategyLaunchDate: any; // Starting / launch date computed from strategy run
  hasStrategyFinalDate: any; // End / final date set by user
  hasStrategyLaunchDate: any; // Starting / launch date set by user

  constructor(
    protected readonly environment: AppEnvironment,
    protected wysiwygState: WysiwygState,
    protected readonly launchDate?: number,
    protected readonly finalDate?: number
  ) {
    this.apiInstruments = environment.http.instruments;
    this.apiLists = environment.http.lists;
    this.apiStrategies = environment.http.strategies;
    this.apiSystematicProducts = environment.http.systematicProducts;
    this.apiUtils = environment.http.utils;
    this.apiProduct = new Product(
      environment,
      wysiwygState.target as SystematicProduct
    );
  }

  BLENDED_BENCHMARK_TAG = "COLLECTION";

  async retrieve() {
    await this.apiProduct.initProduct();
    const wysiwygState = this.wysiwygState;
    // Check type so later the target is of correct type
    if (wysiwygState.targetType !== "SYSTEMATIC") {
      throw new Error("Invalid target type, must be SYSTEMATIC");
    }

    const computing: {
      allocationAt: any; // systematic: data to be merged
      instruments: ListPosition[];
      snapshotAt: any; // systematic: data to be merged
    } = {
      allocationAt: null, // systematic: data to be merged
      instruments: [],
      snapshotAt: null, // systematic: data to be merged
    };
    const response: {
      priceBenchmark: any;
      priceList: any;
      strategy: any;
      systematic: any;
    } = {
      priceBenchmark: null,
      priceList: null,
      strategy: null,
      systematic: null,
    };
    const responseCompute: any = {};

    const product = wysiwygState.target;

    this.hasStrategyLaunchDate = this.launchDate != null;
    this.strategyLaunchDate = this.launchDate;
    this.hasStrategyFinalDate = this.finalDate != null;
    this.strategyFinalDate = this.finalDate;

    const productCurves = await this.apiProduct.getCurves();

    const priceCurves = productCurves.CURVES;
    const listPrice = priceCurves.H;
    const benchmarkPrice = priceCurves.B;
    const curves = prepareSystematicPortfoliosCurves(
      listPrice,
      benchmarkPrice,
      this.launchDate,
      this.finalDate
    );

    let allocations = getDataSinceLaunchDate(
      this.launchDate,
      productCurves.POS.H
    );

    // Adapt from final date also
    allocations = getDataSinceFinalDate(this.finalDate, allocations);

    const lastAllocationIndex = allocations.length - 1;
    const lastAllocationDay = allocations[lastAllocationIndex]["d"];

    const strategyResult = {
      POS: allocations,
      CURVES: curves,
    };

    const strategy = await this.apiProduct.getStrategy();

    const paramsAllocationAt = {
      cutoff: "date",
      date: lastAllocationDay,
      finalDate: this.finalDate,
      listHistory: await this.apiProduct.getHistoricalPortfolio(),
      product: product,
    };

    let paramsInstrumentHedging;
    if (
      strategy.params.hedging != null &&
      strategy.params.hedging.instrument != null
    ) {
      paramsInstrumentHedging = {
        properties:
          sectionProperties[
            "trendrating-report/systematic-portfolios/benchmark"
          ]["properties"],
        symbols: [strategy.params.hedging.instrument],
        type: "security" as const,
      };
    }

    // 2020-12-29
    // Get allocationAt first because it is needed
    // to calculate the correct snapshot, if a cut
    // value from the end is selected

    const rawSnapShot = await this.apiProduct.snapshot();

    responseCompute.systematic = await httpAll({
      allocationAt: this.apiSystematicProducts.allocationAt(paramsAllocationAt),
      analytics: await this.apiProduct.getAnalytics(
        "analytics",
        this.launchDate,
        this.finalDate
      ),
      curves: curves,
      instrumentHedging:
        paramsInstrumentHedging != null
          ? this.apiInstruments.fetch(paramsInstrumentHedging)
          : null,
      keyFacts: this.apiProduct.getAnalytics(
        "keyFacts",
        this.launchDate,
        this.finalDate
      ),
      performances: null,
      priceBenchmark: response.priceBenchmark,
      priceList: response.priceList,
      snapshot: rawSnapShot.snapShot,
      strategy: strategy,
      strategyResult: strategyResult,
      whiteList:
        strategy.params.universe?.whiteList?.id != null
          ? this.apiLists.get(strategy.params.universe.whiteList.id)
          : null,
    });

    // Adapt systematic data to get compatibility on
    // strategy/portfolios widgets
    // The method _prepareParams was also adjusted
    //! TODO WARNING OVERRIDDEN WYSWIWYG STATE
    wysiwygState.originalTarget = wysiwygState.target;
    wysiwygState.target = strategy;

    response.strategy = strategy;
    response.strategy.data = responseCompute.systematic.strategyResult;

    responseCompute.strategy = {
      analytics: responseCompute.systematic.analytics,
      benchmark: this.apiProduct.getBenchmark(),
      holdings: null,
      instrumentHedging: responseCompute.systematic.instrumentHedging,
      keyFacts: responseCompute.systematic.keyFacts,
      performances: responseCompute.systematic.performances,
      whiteList: responseCompute.systematic.whiteList,
      storage: this.apiProduct,
    };

    if (responseCompute.systematic.snapshot?.positions != null) {
      responseCompute.strategy.holdings =
        responseCompute.systematic.snapshot.positions;

      computing.allocationAt = prepareAllocationAtMap(responseCompute);
      computing.snapshotAt = prepareSnapshotAtMap(responseCompute);

      computing["instruments"] = responseCompute["systematic"]["snapshot"][
        "positions"
      ].map(function (instrument) {
        return {
          symbol: instrument["symbol"],
          weight: instrument["weight"],
        };
      });
    }

    return {
      computing,
      response,
      responseCompute,
    };
  }
}
