import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { Lists } from "../../../../../../../api/compute/Lists";
import {
  dataInjector,
  extractSymbols,
  extractSymbolsAndWeights,
} from "../../../../../../../api/compute/commons";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { config } from "../../../../../config-ts";
import {
  messageError,
  messageSuccess,
  removeLoader,
} from "../../../../../utils";
import { widgetsConfiguration } from "../../../../../widgets/widgetsConfiguration";
import Header from "./Header";
import ImportSection from "./ImportSection";
import TableSection from "./TableSection";
import { createEditReducer } from "./reducer";
import { _dataListMeta, getUpdateTime } from "./utils";

import { useTranslation } from "react-i18next";
import { DialogSaveComponent } from "../../../../../ui/commons/DialogSave/DialogSaveComponent";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";
import { Remove } from "../../../../../components/app-infrastructure/workflowBar/actions/remove/Remove";
import { deepClone } from "../../../../../../../deepClone";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    removeLoader();
  }, []);

  const environment = useEnvironment();
  const setup = useMemo(() => {
    return environment.get("setup");
  }, [environment]);
  const productConfiguration = useMemo(() => {
    return environment.get("account")["product"];
  }, [environment]);
  const analysisListRouteEnabled = useMemo(
    () => productConfiguration["configuration"]["analysis_list"]["enabled"],
    [productConfiguration]
  );
  const listAPI = useMemo(() => {
    return new Lists(setup);
  }, [setup]);
  const instrumentsAPI = useMemo(() => {
    return new Instruments(setup);
  }, [setup]);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState(null);

  const params = useParams();

  const initState = useMemo(() => {
    let prototype = listAPI.getPrototype();
    prototype.type = params.type?.toUpperCase() as "PORTFOLIO" | "BASKET";
    return prototype;
  }, [listAPI, params.type]);
  const [state, dispatcher] = useImmerReducer<any, any>(
    createEditReducer,
    initState
  );

  const { broadcast } = useBroadcast();

  const getPositions = useCallback(
    (list) => {
      var positions = list["positions"];

      if (positions.length === 0) {
        var pos = _getPositionsPrepare(
          list,
          {
            data: [],
            dataTotalCount: 0,
          },
          environment
        );
        dispatcher({ type: "SET_POSITIONS", payload: pos });
        dispatcher({ type: "SET_INIT_POSITIONS", payload: pos });
        setIsLoading(false);

        return pos;
      } else {
        var properties =
          widgetsConfiguration["widgets/analysis/collection/edit"][
            "properties"
          ];
        var symbols = extractSymbols(positions);
        var params = {
          filters: [
            {
              dimension: "symbol",
              segments: symbols,
            },
          ],
          page: {
            page: 1,
            rows: symbols.length,
          },
        };

        return instrumentsAPI
          .filterAndFetch(params, "security", properties)
          .then((response) => {
            let pos = _getPositionsPrepare(list, response, environment);
            dispatcher({ type: "SET_POSITIONS", payload: pos });
            dispatcher({ type: "SET_INIT_POSITIONS", payload: pos });

            setIsLoading(false);
          });
      }
    },
    [dispatcher, environment, instrumentsAPI]
  );

  useEffect(() => {
    if (params.id) {
      setIsLoading(true);
      // console.log("editing");
      listAPI
        .portfolioFetch(
          [parseInt(params.id)],
          [
            "name",
            "benchmark",
            "positions",
            "updateTime",
            "weightsDate",
            "type",
            "currency",
            "positionsToday",
          ]
        )
        .then((resp) => {
          let temp = {
            ...resp[0],
            weightsManagement: { currency: null, date: null, type: "fixed" },
          };
          if (temp.weightsDate != null) {
            temp["weightsManagement"].currency =
              temp.currency == null ? "local" : temp.currency;
            temp["weightsManagement"].date = temp.weightsDate;
            temp["weightsManagement"].type = "drifting";
          }
          dispatcher({ type: "INIT_EDIT", payload: temp });

          const _list = temp;
          setList(_list);
          const isBasket = _list.type.toLowerCase() === "basket";
          _list["positions"] = isBasket
            ? temp?.positionsToday ?? []
            : temp?.positions ?? [];
          getPositions(_list);
          setWorkflow("s1");
        });
    } else {
      // console.log("creating");
      setWorkflow("s0");
    }
  }, [dispatcher, getPositions, listAPI, params]);

  //#region
  const setName = useCallback(
    (name) => {
      dispatcher({ type: "SET_NAME", payload: name });
    },
    [dispatcher]
  );
  const setWeights = useCallback(
    (weight) => {
      dispatcher({ type: "SET_WEIGHTS", payload: weight });
    },
    [dispatcher]
  );
  const setBenchmark = useCallback(
    (benchmark) => {
      dispatcher({ type: "SET_BENCHMARK", payload: benchmark });
    },
    [dispatcher]
  );
  const addHoldingHandler = useCallback(
    (holding) => {
      let temp = [...state.positions];
      temp.push(holding);
      dispatcher({ type: "SET_POSITIONS", payload: temp });
    },
    [dispatcher, state.positions]
  );

  const setUniquesHandler = useCallback(
    (holdings) => {
      let temp = [...holdings];
      dispatcher({ type: "SET_POSITIONS", payload: temp });
    },
    [dispatcher]
  );
  const updateHoldingHandler = useCallback(
    (holding) => {
      // Cause row position start by 1 and not from 0
      let _positions = deepClone(state.positions);
      let obj: any = null;
      for (let i = 0; i < _positions.length; i++) {
        obj = _positions[i];

        if (obj.symbol === holding.symbol) {
          obj.weight = holding.weight;
        }
      }

      dispatcher({ type: "SET_POSITIONS", payload: _positions });
    },
    [dispatcher, state.positions]
  );
  const removeHoldingHandler = useCallback(
    (symbol) => {
      let temp = [...state.positions];
      temp = temp.filter((item) => item.symbol !== symbol);
      dispatcher({ type: "SET_POSITIONS", payload: temp });
    },
    [dispatcher, state.positions]
  );
  const listenerEqualize = useCallback(() => {
    let positions: any[] = [...state.positions];
    let length: number = positions.length;
    let weight: number = 1 / length;

    for (let i = 0; i < length; i++) {
      let _temp = { ...positions[i] };
      _temp.weight = weight;
      positions[i] = _temp;
    }
    dispatcher({ type: "SET_POSITIONS", payload: positions });
  }, [dispatcher, state.positions]);
  const listenerNormalize = useCallback(() => {
    let listWeight = _dataListMeta([...state.positions])["weight"];
    let position: any = null;
    let positions = [...state.positions];
    let length = positions.length;
    for (let i = 0; i < length; i++) {
      position = { ...positions[i] };
      position["weight"] = listWeight
        ? position["weight"] / listWeight
        : 1 / length;
      positions[i] = position;
    }
    dispatcher({ type: "SET_POSITIONS", payload: positions });
  }, [dispatcher, state.positions]);
  //#endregion

  const saveNewList = useCallback(
    (_list) => {
      let type = _list.type === "BASKET" ? "Basket" : "Portfolio";

      let [isValid, whatIsWrog]: any = checkValidity(_list);
      if (isValid) {
        listAPI.create(_list).then((response) => {
          if (response.status === "OK") {
            if (analysisListRouteEnabled) {
              navigate(`/app/analysis/lists/${response.data.id}/analyze/`);
            } else {
              navigate(`/app/performanceRise`);
            }
            const [channel, msg] = messageSuccess(
              `${type} ${_list.name} created successfully!`
            );
            broadcast(channel as string, msg);
          } else {
            const [channel, msg] = messageError(
              `${type} ${_list.name} could not be created! contact Trendrating team.`
            );
            broadcast(channel as string, msg);
          }
        });
      } else {
        const [channel, msg] = messageError(
          `${type} is not valid! ${whatIsWrog.join(", ")}`
        );
        broadcast(channel as string, msg);
      }
    },
    [analysisListRouteEnabled, broadcast, listAPI, navigate]
  );

  //#region - WORKFLOW HANDLER
  const [workflow, setWorkflow] = useState("s0");
  useEffect(() => {
    const actions: any = [];
    let action: any = null;

    switch (workflow) {
      case "s1": {
        // portfolio selected
        const _temp = { ...state };
        delete _temp.initvalue;
        const init = { ...state.initvalue };

        //Back
        action = {
          componentJSX: (
            <li
              className={"menu__item"}
              onClick={() => {
                navigate(
                  analysisListRouteEnabled
                    ? "/app/analysis/lists"
                    : "/app/performanceRise"
                );
              }}
            >
              {t("Back")}
            </li>
          ),
        };
        actions.push(action);

        //Analyse
        if (
          JSON.stringify(_temp) === JSON.stringify(init) &&
          analysisListRouteEnabled
        ) {
          action = {
            componentJSX: (
              <li
                className={"menu__item"}
                onClick={() =>
                  navigate(`/app/analysis/lists/${params.id}/analyze/`)
                }
              >
                {t("c_list_action_analyze")}
              </li>
            ),
          };
          actions.push(action);
        }

        // Remove
        if (list != null) {
          action = {
            componentJSX: (
              <Remove
                item={list}
                onRemoveDone={() => {
                  // *************************** USAGE ***************************
                  var usage = window.App.usage;
                  var info = {
                    action: "DELETE",
                    actionParam: null,
                    function: "PORTFOLIO_EDIT",
                  };
                  usage.record(info);
                  // *************************** USAGE ***************************

                  navigate(
                    analysisListRouteEnabled
                      ? "/app/analysis/lists"
                      : "/app/performanceRise"
                  );
                }}
                label={t("Delete")}
              />
            ),
          };

          actions.push(action);
        }

        if (JSON.stringify(_temp) !== JSON.stringify(init)) {
          action = {
            componentJSX: (
              <li
                className={"menu__item"}
                onClick={() => setShowDialogSave(true)}
              >
                {t("Save")}
              </li>
            ),
          };
          actions.push(action);
          // }
        }

        if (JSON.stringify(_temp) !== JSON.stringify(init)) {
          action = {
            componentJSX: (
              <li
                className={"menu__item"}
                onClick={() => {
                  dispatcher({ type: "INIT_EDIT", payload: init });
                  dispatcher({
                    type: "SET_POSITIONS",
                    payload: init.positions,
                  });
                  dispatcher({
                    type: "SET_INIT_POSITIONS",
                    payload: init.positions,
                  });

                  ImportSectionRef?.current?.reset();
                }}
              >
                {t("Cancel")}
              </li>
            ),
          };
          actions.push(action);
        }

        break;
      }
      case "s2": {
        // clean up workflow bar while loading data

        break;
      }
      case "s0":
      default: {
        //Back
        action = {
          componentJSX: (
            <li
              className={"menu__item"}
              onClick={() =>
                navigate(
                  analysisListRouteEnabled
                    ? "/app/analysis/lists"
                    : "/app/performanceRise"
                )
              }
            >
              {t("Cancel")}
            </li>
          ),
        };
        actions.push(action);

        //Save
        action = {
          componentJSX: (
            <li className={"menu__item"} onClick={() => saveNewList(state)}>
              {t("Save")}
            </li>
          ),
        };
        actions.push(action);
      }
    }

    var message = {
      from: "PORTFOLIO_HOME",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    analysisListRouteEnabled,
    broadcast,
    dispatcher,
    list,
    navigate,
    params.id,
    saveNewList,
    state,
    t,
    workflow,
  ]);
  //#endregion

  const [showDialogSave, setShowDialogSave] = useState(false);
  const onSave = useCallback(() => {
    listAPI.update(state).then((response) => {
      if (response.status === "OK") {
        if (analysisListRouteEnabled) {
          navigate(`/app/analysis/lists/${response.data.id}/analyze/`);
        } else {
          navigate("/app/performanceRise");
        }
        const [channel, msg] = messageSuccess(
          `${state.name} saved successfully`
        );
        broadcast(channel as string, msg);
      }
    });
  }, [analysisListRouteEnabled, broadcast, listAPI, navigate, state]);
  const onSaveAs = useCallback(
    (newName) => {
      let _temp = { ...state, name: newName };
      //! fare il controllo per impedire di salvare una lista senza nome
      saveNewList(_temp);
    },
    [saveNewList, state]
  );

  const ImportSectionRef = useRef<any>(null);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      overflow={"hidden"}
      position={"relative"}
    >
      <Box display={"flex"} flexDirection={"column"} p={1}>
        <Header
          type={state.type}
          setName={setName}
          setWeights={setWeights}
          name={state?.name ?? null}
          weights={state?.weightsManagement ?? null}
          benchmark={state.benchmark ?? undefined}
          setBenchmark={setBenchmark}
        />
      </Box>
      <Box
        overflow={"hidden"}
        display={"flex"}
        position={"relative"}
        p={1}
        pt={0}
        gap={1}
        height={"100%"}
      >
        <TableSection
          listenerNormalize={listenerNormalize}
          listenerEqualize={listenerEqualize}
          removeHoldingHandler={removeHoldingHandler}
          isLoading={isLoading}
          addHoldingHandler={addHoldingHandler}
          updateHoldingHandler={updateHoldingHandler}
          updateTime={state.updateTime ? getUpdateTime(state.updateTime) : null}
          positions={state.positions}
          type={state.type}
        />

        <ImportSection
          ref={ImportSectionRef}
          setUniques={(arrHoldings) => {
            setUniquesHandler(arrHoldings);
          }}
          addHoldingHandler={addHoldingHandler}
        />
      </Box>
      {showDialogSave && (
        <DialogSaveComponent
          hide={() => setShowDialogSave(false)}
          item={{ name: state.name }}
          dialogType={state.type ?? ""}
          onSave={onSave}
          onSaveAs={onSaveAs}
          onRename={null}
        />
      )}
    </Box>
  );
}

const _getPositionsPrepare = (list, response, environment) => {
  let symbolsAndWeights = extractSymbolsAndWeights(list["positions"]);
  let positions = dataInjector(response["data"], symbolsAndWeights, ["weight"]);

  // sanitize weights and add _s_where for sort by name
  let market: any = null;
  let position: any = null;
  const taxonomies = environment.get("rawTaxonomies");
  const txFields = environment.get("setup")["taxonomyFields"];
  let weight: any = null;
  let pType = "Stock";
  for (let i = 0, length = positions.length; i < length; i++) {
    position = positions[i];
    pType = position.type;

    market =
      pType === "ETF"
        ? taxonomies[txFields["ETF"]["etfgeo"]]
        : taxonomies[txFields["security"]["country"]];
    position["_s_where"] =
      market[pType === "ETF" ? position["etfgeo"] : position["country"]][
        "name"
      ];

    weight = parseFloat(position["weight"]);
    if (isNaN(weight)) {
      position["weight"] = 0;
    }
  }

  return positions;
};

const checkValidity = (listOrBasket) => {
  let isValid = true;
  let whatIsWrog: string[] = [];
  const type = listOrBasket.type === "BASKET" ? "Basket" : "Portfolio";
  if (listOrBasket.name.length < 1) {
    isValid = false;
    whatIsWrog.push("name is required");
  }
  if (listOrBasket.positions.length < 1) {
    isValid = false;
    whatIsWrog.push(`${type} must have at least 1 holding.`);
  }
  return [isValid, whatIsWrog];
};
